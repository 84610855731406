import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import findKey from 'lodash/findKey'
import merge from 'lodash/merge'
import pick from 'lodash/pick'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import {
  LabelTypes,
  IssuePriorityOptions,
  IssueResolutionOptions,
} from 'constants/index'

const fieldOptions = {
  vendor_id: {
    label: LabelTypes.VENDOR,
    type: FormField.types.SEARCHABLE_SELECT,
    controlProps: {
      noOptionsMessage: () => `No ${LabelTypes.VENDORS.toLowerCase()} found`,
    },
    validation: yup.string().required(),
  },
  summary: {
    label: 'Summary',
    placeholder: 'Enter issue summary',
    controlProps: { maxLength: 255 },
    validation: yup.string().required(),
  },
  description: {
    label: 'Description',
    type: FormField.types.FORMATTABLE_EDITOR,
    placeholder: 'Enter issue details',
    controlProps: { rows: 3, toolbarCondensed: true },
  },
  files: {
    label: 'Files',
    type: FormField.types.FILES_INPUT,
    controlProps: { maxFiles: 3 },
  },
  priority: {
    label: 'Priority',
    type: FormField.types.SELECT,
    options: IssuePriorityOptions,
    controlProps: { style: { width: 196 } },
    validation: yup.string().required(),
  },
  due_at: {
    label: 'Due date',
    type: FormField.types.DATE,
    controlProps: { showDurationButtons: true },
    validation: yup.string().required().typeError('Due date is required'),
  },
  issue_category_id: {
    label: 'Category',
    type: FormField.types.CREATABLE_SELECT,
    controlProps: {
      placeholder: 'Select or Create New...',
      noOptionsMessage: () => 'No categories found',
      formatCreateLabel: (i) => `Create new category "${i}"`,
      isClearable: true,
    },
  },
  resolution: {
    label: 'Resolution',
    type: FormField.types.SELECT,
    options: IssueResolutionOptions,
  },
}

const IssueModal = ({
  initialFormData,
  vendorNames,
  vendorNamesLoading,
  issueCategories,
  issueCategoriesLoading,
  hiddenFields,
  disabledFields,
  ...rest
}) => {
  const computedFieldOptions = React.useMemo(() => {
    const options = merge({}, fieldOptions, {
      vendor_id: {
        options: map(vendorNames, (e) => ({ label: e.name, value: e.id })),
        controlProps: {
          isLoading: vendorNamesLoading,
        },
      },
      issue_category_id: {
        options: map(issueCategories, (e) => ({ label: e.name, value: e.id })),
        controlProps: {
          isLoading: issueCategoriesLoading,
        },
      },
    })
    hiddenFields.forEach((key) => delete options[key])

    // Hide "Category" field globally if there are no existing issue categories
    if (!issueCategories || issueCategories.length === 0) {
      delete options.issue_category_id
    }

    disabledFields.forEach((key) => {
      if (options[key]) {
        options[key].disabled = true
      }
    })

    const firstEnabledKey = findKey(options, (opt) => !opt.disabled)
    options[firstEnabledKey].controlProps = {
      ...options[firstEnabledKey].controlProps,
      autoFocus: true,
    }

    return options
  }, [
    hiddenFields,
    disabledFields,
    vendorNames,
    vendorNamesLoading,
    issueCategories,
    issueCategoriesLoading,
  ])
  const formConfig = React.useMemo(() => {
    const initialValues = {
      ...mapValues(computedFieldOptions, 'initialValue'),
      // Pick only relevant properties from initialFormData prop and feed into formik's initialValues
      ...pick(initialFormData, Object.keys(computedFieldOptions)),
    }
    const validationSchema = yup
      .object()
      .shape(
        mapValues(computedFieldOptions, (o) =>
          o.validation ? o.validation.label(o.label) : undefined,
        ),
      )
    return { initialValues, validationSchema }
  }, [computedFieldOptions, initialFormData])
  return (
    <FormDialog
      {...rest}
      submitButtonLabel="Save Issue"
      formConfig={formConfig}
    >
      {map(
        computedFieldOptions,
        ({ validation, initialValue, ...other }, key) => (
          <FormField key={key} {...other} name={key} horizontal />
        ),
      )}
    </FormDialog>
  )
}

IssueModal.propTypes = {
  initialFormData: PropTypes.object,
  vendorNames: PropTypes.array,
  vendorNamesLoading: PropTypes.bool,
  issueCategories: PropTypes.array,
  issueCategoriesLoading: PropTypes.bool,
  hiddenFields: PropTypes.array,
  disabledFields: PropTypes.array,
}

IssueModal.defaultProps = {
  initialFormData: {},
  vendorNames: [],
  issueCategories: [],
  hiddenFields: [],
  disabledFields: [],
}

export default IssueModal
