import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import Button from 'components-v2/atoms/Button'
import IconButton from 'components-v2/atoms/IconButton'
import SelectField from 'components-v2/molecules/FormField/SelectField'
import FormField from 'components-v2/molecules/FormField'
import Drawer from 'components-v2/molecules/Drawer'
import Menu from 'components-v2/molecules/Menu'
import ToolbarGroup from 'components-v2/molecules/ToolbarGroup'
import { Row, Col } from 'components-v2/organisms/Layout'
import FormDrawer from 'components-v2/organisms/FormDrawer'
import { useRoleAtLeast, useAuthorizations } from 'hooks'
import { useClientUserGroupsFetcher } from 'apis'
import { UserOrgRoles } from 'utils/roles'
import { LabelTypes } from 'constants/index'
import VendorPermissionsField from '../VendorPermissionsField'

const validationSchema = yup.object().shape({
  email: yup.string().label('Email').required().email(),
})

const EditUserModal = ({
  initialValues,
  user,
  vendors,
  currentUserId,
  permittedOrgRoles,
  isClient,
  onSubmit,
  onRemove,
  onClose,
  ...rest
}) => {
  const { data: allGroups } = useClientUserGroupsFetcher(undefined, {
    keepPreviousData: true,
  })
  const groupOptions = React.useMemo(
    () => allGroups?.map(({ name }) => ({ label: name, value: name })),
    [allGroups],
  )
  const [
    canViewRoles,
    canManage,
    canManageNotifications,
    canManageGroups,
    canAdmin,
    canRemove,
  ] = useAuthorizations(
    ['view_roles', 'User'],
    ['manage', 'User'],
    ['manage_user_notifications', 'User'],
    ['manage_user_groups', 'User'], // bringing in for pending UX update
    'admin',
    ['delete', 'User'],
  )

  const hasApiAccess = user.org_role === 'manage' || user.org_role === 'admin'
  const roleAtLeast = useRoleAtLeast(user.org_role)
  const roleSelectionEnabled =
    canManage && roleAtLeast && user.id !== currentUserId
  const vendorRoleSelectionVisible = canViewRoles || roleSelectionEnabled
  const orgRoleList = roleSelectionEnabled
    ? permittedOrgRoles
    : [UserOrgRoles[user.org_role]]

  const notificationsFieldEnabled =
    user.id === currentUserId || canManageNotifications
  const apiPermissionFieldVisible = isClient && canAdmin
  const groupsFieldVisible = isClient && canManageGroups
  const canUpdate =
    notificationsFieldEnabled ||
    apiPermissionFieldVisible ||
    groupsFieldVisible ||
    (isClient && roleSelectionEnabled)

  return (
    <FormDrawer
      {...rest}
      formConfig={{ initialValues, validationSchema }}
      onClose={onClose}
      onSubmit={onSubmit}
      renderHeader={(formProps) => (
        <Drawer.Header title={user.name} onClose={onClose}>
          <ToolbarGroup gap={16}>
            {canUpdate && (
              <Button
                type="submit"
                color="primary"
                disabled={formProps.isSubmitting}
              >
                Save
              </Button>
            )}
            {(!isClient || canRemove) &&
              user.id !== currentUserId &&
              roleAtLeast && (
                <Menu placement="bottom-end" offset={10}>
                  <Menu.Button as={IconButton} icon="fa fa-ellipsis-v" />
                  <Menu.Items size="small" arrow>
                    <Menu.Item onClick={onRemove}>Delete User</Menu.Item>
                  </Menu.Items>
                </Menu>
              )}
          </ToolbarGroup>
        </Drawer.Header>
      )}
    >
      {(formProps) => (
        <>
          <Row>
            <Col sm={6}>
              <FormField name="name" label="Name" disabled />
            </Col>
            <Col sm={6}>
              <FormField name="email" label="Email" disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <FormField
                name="email_notifications"
                label="Notifications"
                type={FormField.types.TOGGLE}
                disabled={!notificationsFieldEnabled}
              />
            </Col>
            {apiPermissionFieldVisible && (
              <Col sm={6}>
                <FormField
                  disabled={!hasApiAccess}
                  name="api_enabled"
                  label="API Permission"
                  type={FormField.types.TOGGLE}
                  data-test-id="api-enabled-toggle"
                />
              </Col>
            )}
          </Row>
          {groupsFieldVisible && (
            <FormField
              name="groups"
              label="Groups"
              type={FormField.types.SEARCHABLE_SELECT}
              options={groupOptions}
              controlProps={{
                isMulti: true,
              }}
            />
          )}
          {isClient && (
            <Row>
              <Col sm={6}>
                <FormField
                  name="org_role"
                  label="Access Role"
                  type={FormField.types.SELECT}
                  options={orgRoleList}
                  disabled={!roleSelectionEnabled}
                />
              </Col>
            </Row>
          )}
          {isClient && vendorRoleSelectionVisible && (
            <>
              <h4>{LabelTypes.VENDOR} Permissions</h4>
              <p>
                {LabelTypes.VENDOR} selection is only supported for the
                &ldquo;Status Only&rdquo; role.
              </p>
              {UserOrgRoles[formProps.values.org_role].allows_vendors ? (
                <FormField
                  name="vendor_roles"
                  label="Access to"
                  component={VendorPermissionsField}
                  vendors={vendors}
                  hasIndividualRoles={false} // need to revisit (was: user['org_role'] === UserOrgRoleTypes.CUSTOM,)
                  disabled={!roleSelectionEnabled}
                />
              ) : (
                <Row>
                  <Col sm={6}>
                    <SelectField
                      label="Access to"
                      value="all"
                      disabled
                      options={[
                        {
                          value: 'all',
                          label: `All ${LabelTypes.VENDORS}`,
                        },
                      ]}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      )}
    </FormDrawer>
  )
}

EditUserModal.propTypes = {
  initialValues: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  vendors: PropTypes.array.isRequired,
  currentUserId: PropTypes.string.isRequired,
  permittedOrgRoles: PropTypes.array.isRequired,
  isClient: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditUserModal
